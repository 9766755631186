<template>
  <div class="c-getting-started">
    <div class="c-getting-started__title">
      <h2 class="c-getting-started__title-subtitle">Would you like to connect with a</h2>
      <h1 class="c-getting-started__title-text">digital assets specialist?</h1>
    </div>
    <div class="c-getting-started__description">
      <p class="c-getting-started__description-text">Our digital assets team at Sparrow is well-versed and equipped with a
        suite of digital asset products and solutions for institutions and accredited clients. Get in touch with the team
        today.</p>
    </div>
    <button class="c-getstarted__body-title__button" @click="contactUsClick">{{ "Contact Us >" }}</button>
  </div>
</template>

<script>
export default {
  name: 'GettingStarted',
  methods: {
    contactUsClick: function () {
      this.openLink(this.contactUsUrl())
    }
  },
}
</script>
