<template>
  <div class="page">
    <div class="c-page o-page-wrapper">
      <Sidebar :status="'consumer'" :elementClass="'.c-suggestion'"></Sidebar>
      <div class="o-sidebar-content">
        <div class="c-page__content c-page__content_no-bottom">
          <Content></Content>
          <LikeSuggestion></LikeSuggestion>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Sidebar from '@/components/Sidebar.vue'
import Footer from '@/components/Footer.vue'
import Content from '@/components/institutional/GettingStartedContent.vue'
import LikeSuggestion from '@/components/LikeSuggestion.vue'

export default {
  name: 'GettingStarted',
  components: {
    Sidebar,
    Footer,
    Content,
    LikeSuggestion
  },
  data() {
    return {
      theme: 'two',
    }
  },
  mounted: function () {
    this.updateTitleMeta('Sparrow | Connect with a Digital Assets Specialist Today')
    this.updateDescriptionMeta('Our digital asset solutions team is well-versed and equipped with a full suite of financial products and solutions catered for institutions and accredited clients.')
    this.updateKeywordMeta('Sparrow, wealth management')
  }
}
</script>
